import { computed, ref } from 'vue';
import axios from 'axios';
import User from '@/model/User';
import { useLocalStorage, useNetwork } from '@vueuse/core';

const user = ref<User | null>(null);
const isAuthenticated = computed(() => user.value !== null);

const persistentToken = useLocalStorage<string | null>('token', null);
const persistentJwtToken = useLocalStorage<string | null>('jwt-token', null);

const { isOnline } = useNetwork();

const authenticate = async (
  event: string | number,
  token: string | null = null
) => {
  try {
    if (token) {
      persistentToken.value = token;
    }
    if (persistentToken.value === null) {
      throw new Error('No token found');
    }
    if (isOnline.value) {
      persistentJwtToken.value = await axios
        .post(
          '/api/app/login_check',
          {},
          {
            params: { event, xtoken: persistentToken.value },
          }
        )
        .then((res) => res.data.token);
    } else if (persistentJwtToken.value === null) {
      throw new Error('No token found in offline mode');
    } else {
      addEventListener(
        'online',
        () => {
          window.location.reload();
        },
        { once: true }
      );
    }
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${persistentJwtToken.value}`;
    user.value = await axios
      .get(`/api/event/${event}/user/current`)
      .then((res) => res.data);
  } catch (error) {
    clearSession();
  }
};

const hasSession = () => persistentToken.value !== null;

const clearSession = () => {
  user.value = null;
  persistentToken.value = null;
  window.dispatchEvent(new Event('logout'));
};

export const useSecurity = () => ({
  isAuthenticated,
  persistentToken,
  user,
  hasSession,
  authenticate,
  clearSession,
});
