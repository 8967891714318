export default {
  start: {
    btnSignInRequest: 'Request sign in link',
    btnSignInQR: 'Sign in via QR',
    btnSignInQRHelp: 'Or click on the received link in your mailbox',
  },
  loginRequest: {
    modalTitle: 'Check your inbox',
    modalBody:
      'If we found a matching registration you will receive an email with a QR code.<br/>You can sign in to the app by scanning it or by clicking the sign-in link from phone.',
    btn: {
      toStart: 'Back to start',
      scanQr: 'Scan QR code',
    },
  },
  navigation: {
    more: 'More',
    pages: 'Pages',
    contacts: 'Contacts',
    myContacts: 'My contacts',
    scanBadge: 'Scan new contacts',
    actions: 'Actions',
    logout: 'Logout',
  },
  network: {
    offline: 'Offline mode<br/>Some content may not be up-to-date',
    online: 'Online',
    newContentAvailable: 'New content is available',
    reload: 'reload',
  },
  profile: {
    ticketList: 'My Ticket(s)',
    connected: 'Added user to contacts',
  },
  button: {
    close: 'Close',
  },
  filter: {
    contacts: 'Contacts',
    showResults: 'Show results',
  },
  postCategory: {
    view: 'View',
  },
  attendeeList: {
    addContact: 'Add contacts',
    addContactInfo:
      'Have someone scan your QR code within the app to connect and share contact information.',
  },
  program: {
    agendaFull: 'Full agenda',
    agendaRegistered: 'My agenda',
    empty: 'No items found for this day',
  },
  form: {
    eventCode: 'Event code',
    email: 'Email',
    btn: {
      request: 'Request',
    },
  },
};
