import { reactive, ref } from 'vue';
import axios from 'axios';
import Event from '@/model/Event';
import Day from '@/model/Day';
import { useSecurity } from '@/state/security';

const { user } = useSecurity();

const days = reactive<Day[]>([]);
const daysPromise = ref<Promise<void>>();

window.addEventListener('logout', () => {
  days.length = 0;
});

const hasProgramRegistrations = () =>
  user.value!.registrations[0].programRegistration.length !== 0;

const initialiseDays = async (event: Event): Promise<void> => {
  days.length = 0;
  await axios
    .get<Day[]>(`/api/event/${event.id}/days`)
    .then((res) => res.data)
    .then((data) => days.push(...data));
  days.forEach((day) => {
    day.startDate = new Date(day.startDate);
    day.endDate = new Date(day.endDate);
  });

  for (const day of days) {
    day.programs = await axios
      .get(`/api/days/${day.id}/program`, {
        params: { personalized: true },
      })
      .then((res) => res.data);
    day.programs.forEach((program) => {
      program.registered =
        user.value!.registrations[0].programRegistration.some(
          (pr) => pr.program.id === program.id
        );
    });
  }
};

export const useProgramState = () => ({
  initialiseDays,
  hasProgramRegistrations,
  daysPromise,
  days,
});
