// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { firebaseOptions, vapidKey } from '@/firebase/firebaseConfig';
import axios from 'axios';

declare global {
  interface Window {
    webkit: any;
  }
}

const app = initializeApp(firebaseOptions);
const messaging = getMessaging(app);

const saveFcmToken = (eventId: number, token: string) => {
  axios.post(`/api/firebase/event/${eventId}/subscribe/${token}`);
};
export const requestPushPermission = (eventId: number) => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }
  // window.webkit is available via the ios wrapper
  if (window?.webkit?.messageHandlers?.['push-permission-request']) {
    window.webkit.messageHandlers['push-permission-request'].postMessage(
      'push-permission-request'
    );
    window.addEventListener(
      'push-permission-request',
      (message: any) => {
        if (message?.detail?.granted === true) {
          saveFcmToken(eventId, message.detail.token);
        }
      },
      false
    );
  } else if ('Notification' in window) {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        navigator.serviceWorker
          .getRegistration('/')
          .then((serviceWorkerRegistration) => {
            getToken(messaging, {
              vapidKey,
              serviceWorkerRegistration,
            }).then((token) => {
              saveFcmToken(eventId, token);
            });
          });
      }
    });
  }
};
