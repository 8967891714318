import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useSecurity } from '@/state/security';
import { useEventState } from '@/state/eventState';
import { start } from '@popperjs/core';
import { generateRedirectUrl, standalone } from '@/util/userAgentChecker';

const { initializeEventData, getHomepage, eventId, eventData } =
  useEventState();
const { isAuthenticated, hasSession, authenticate, clearSession } =
  useSecurity();

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'start',
    component: () => import('@/views/start/StartView.vue'),
    beforeEnter: async (to, from, next) => {
      if (eventId.value !== null && isAuthenticated.value) {
        return next({ name: 'event-layout' });
      }
      next();
    },
  },
  {
    path: '/qr-auth',
    name: 'qr-auth',
    component: () => import('@/views/start/QrView.vue'),
  },
  {
    path: '/login-request',
    name: 'login-request',
    component: () => import('@/views/start/LoginRequestView.vue'),
  },
  {
    path: '/auth/:event(\\d+)/:token',
    name: 'auth',
    component: () => import('@/views/auth/AuthView.vue'),
    props: (route) => ({
      event: Number(route.params.event),
      token: route.params.token,
    }),
  },
  {
    path: '/event',
    name: 'event-layout',
    component: () => import('@/layout/EventLayout.vue'),
    beforeEnter: async (to, from, next) => {
      // return to home if trying to access
      if (eventId.value === null) {
        return next({ name: 'start' });
      }
      if (eventData.value === null) {
        try {
          await initializeEventData();
        } catch (e) {
          console.error(e);
          clearSession();
          return next({ name: start });
        }
      }
      const homepage = getHomepage();
      if (to.name === 'event-layout' && homepage !== null) {
        return next(homepage);
      }
      return next();
    },
    children: [
      {
        path: 'page/:slug',
        name: 'page',
        component: () =>
          import(
            /* webpackChunkName: "main" */ '@/views/event/text/TextPageView.vue'
          ),
        props: (route) => ({ slug: route.params.slug }),
        meta: {
          auth: true,
        },
      },
      {
        path: 'profile',
        name: 'web-profile',
        component: () =>
          import(
            /* webpackChunkName: "main" */ '@/views/event/profile/ProfileView.vue'
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: 'program',
        name: 'web-day',
        component: () =>
          import(
            /* webpackChunkName: "main" */ '@/views/event/program/ProgramView.vue'
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: '/web-schedule',
        name: 'web-schedule',
        redirect: () => ({ name: 'web-day' }),
      },
      {
        path: '/web-session/:id',
        name: 'web-session',
        component: () =>
          import(
            /* webpackChunkName: "main" */ '@/views/event/session/SessionView.vue'
          ),
        props: (route) => ({ id: route.params.id }),
        meta: {
          auth: true,
        },
      },
      {
        path: 'post-category/:id',
        name: 'web-post-category',
        component: () =>
          import(
            /* webpackChunkName: "main" */ '@/views/event/post/PostCategoryView.vue'
          ),
        props: (route) => ({ id: route.params.id }),
        meta: {
          auth: true,
        },
      },
      {
        path: 'post/:id',
        name: 'web-post',
        component: () =>
          import(
            /* webpackChunkName: "main" */ '@/views/event/post/PostView.vue'
          ),
        props: (route) => ({ id: route.params.id }),
        meta: {
          auth: true,
        },
      },
      {
        path: 'attendees',
        name: 'web-attendees',
        component: () =>
          import(
            /* webpackChunkName: "main" */ '@/views/event/attendees/AttendeeListView.vue'
          ),
        props: () => ({ type: 'list' }),
        meta: {
          topNav: false,
        },
      },
      {
        path: 'attendees-list',
        name: 'web-attendees-list',
        component: () =>
          import(
            /* webpackChunkName: "main" */ '@/views/event/attendees/AttendeeListView.vue'
          ),
        props: () => ({ type: 'card' }),
        meta: {
          topNav: false,
        },
      },
      {
        path: 'meet-and-connect',
        name: 'meet-and-connect',
        component: () =>
          import(
            /* webpackChunkName: "attendees" */ '@/views/event/meetandconnect/MeetAndConnectView.vue'
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: 'contact-scan',
        name: 'app-contact-scan',
        component: () => import('@/views/event/contact/ConnectView.vue'),
        meta: {
          auth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

router.beforeEach(async (to, from, next) => {
  if (to.query.allowInBrowser === 'true') {
    sessionStorage.setItem('allowInBrowser', '1');
  }
  if (
    process.env.NODE_ENV !== 'development' &&
    !standalone &&
    sessionStorage.getItem('allowInBrowser') === null
  ) {
    window.location.href = generateRedirectUrl(to);
  }
  if (eventId.value && !isAuthenticated.value && hasSession()) {
    await authenticate(eventId.value);
  }
  if (
    (to.meta.auth && !isAuthenticated.value) ||
    (to.meta.auth === false && isAuthenticated.value)
  ) {
    return next({ name: 'start' });
  }
  return next();
});
