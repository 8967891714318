// @ts-ignore
import UAParser from 'ua-parser-js';
import { RouteLocationNormalized } from 'vue-router';

const standaloneDetected =
  window.matchMedia('(display-mode: standalone)').matches ||
  (window.navigator as any).standalone === true;

const parser = new UAParser();
const browserName = parser.getBrowser().name;

/**
 * we have to make exceptions for the samsung browser and firefox.
 * firefox: https://bugzilla.mozilla.org/show_bug.cgi?id=1796434
 * the samsung browser uses a very outdated version of chromium
 */
export const standalone =
  standaloneDetected ||
  browserName === 'Samsung Browser' ||
  browserName === 'Firefox';

export const generateRedirectUrl = (to: RouteLocationNormalized) => {
  const url = `${process.env.VUE_APP_API_HOST}/app`;
  if (to.name === 'auth') {
    return `${url}?event=${to.params.event}&token=${to.params.token}`;
  }
  return url;
};
