import { ref } from 'vue';
import axios from 'axios';
import Event from '@/model/Event';
import { RouteLocationRaw } from 'vue-router';
import MenuItem from '@/model/MenuItem';
import { useLocalStorage, useNetwork } from '@vueuse/core';
import { useProgramState } from '@/state/programState';
import { i18n } from '@/main';
import { requestPushPermission } from '@/firebase/firebase';

const eventData = ref<null | Event>(null);
const eventId = useLocalStorage<number | null>('eventId', null);

const mainItems = ref<MenuItem[]>([]);
const overflowItems = ref<MenuItem[]>([]);

const getHomepage = () =>
  mainItems.value[0] === undefined ? null : generateRoute(mainItems.value[0]);

const hexToRgb = (hex: string): string => {
  const bigint = parseInt(hex.replace('#', ''), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `${r},${g},${b}`;
};

/**
 * Calculates the text color based on the background color
 * @param hex
 */
const textColor = (hex: string): string => {
  const rgb = hexToRgb(hex).split(',').map(Number);
  const yiq = rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114;
  return yiq >= 128 ? '#000000' : '#FFFFFF';
};

const setCssVar = (name: string, hex: string, toRgb = false) => {
  document.documentElement.style.setProperty(
    `--${name}`,
    toRgb ? hexToRgb(hex) : hex,
    'important'
  );

  window.addEventListener(
    'logout',
    () => {
      document.documentElement.style.removeProperty(`--${name}`);
      eventData.value = null;
      mainItems.value = [];
      overflowItems.value = [];
    },
    { once: true }
  );
};

const loadCustomCss = (eventId: number) => {
  const link = document.createElement('link');
  link.id = 'custom-event-css';
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.crossOrigin = 'anonymous"';
  link.href = `${process.env.VUE_APP_API_HOST}/api/event/${eventId}/custom-css`;
  link.media = 'all';

  document.head.appendChild(link);
  window.addEventListener(
    'logout',
    () => document.getElementById('custom-event-css')?.remove(),
    { once: true }
  );
};

const generateRoute = (
  menuItem: MenuItem,
  addState = false
): RouteLocationRaw => {
  const state = addState ? { title: menuItem.title } : undefined;
  if (menuItem.component !== null) {
    const params: { [key: string]: string } = {};
    menuItem.params.forEach((param) => (params[param.key] = param.value));
    return { name: menuItem.component, params, state };
  }

  if (menuItem.page !== null) {
    return { name: 'page', params: { slug: menuItem.page.slug }, state };
  }

  return menuItem.url ?? '';
};

const filteredMenuItems: Array<string | null> = [
  'web-matchmaker',
  'web-dashboard',
  'sign-in',
];

const initializeEventData = async () => {
  eventData.value = await axios
    .get<Event>(`/api/event/${eventId.value}/data`)
    .then((res) => res.data);

  loadCustomCss(eventData.value!.id);

  setCssVar('bs-primary-rgb', eventData.value!.primaryColor, true);
  setCssVar('bs-primary', eventData.value!.primaryColor);
  setCssVar('bg-primary-text-color', textColor(eventData.value!.primaryColor));
  setCssVar('bs-secondary-rgb', eventData.value!.secondaryColor, true);

  i18n.global.locale.value = eventData.value!.defaultLanguage;

  eventData
    .value!.menuItems.sort((a, b) => a.position - b.position)
    .forEach((menuItem) => {
      if (
        ['web-matchmaker', 'web-dashboard', 'sign-in'].includes(
          String(menuItem.component)
        )
      ) {
        return;
      }
      if (
        filteredMenuItems.includes(menuItem.component) ||
        menuItem.hiddenForSignedIn
      ) {
        return;
      }
      if (mainItems.value.length < 3 && menuItem.forceOverflow !== true) {
        mainItems.value.push(menuItem);
      } else {
        overflowItems.value.push(menuItem);
      }
    });
  mainItems.value.forEach((i) => (i.route = generateRoute(i)));
  overflowItems.value.forEach((i) => (i.route = generateRoute(i, true)));

  if (typeof mainItems.value[0]?.route === 'string') {
    throw new Error('Event home page cannot be a URL');
  }

  const { initialiseDays, daysPromise } = useProgramState();

  daysPromise.value = initialiseDays(eventData.value!); // Don't add await

  const { isOnline } = useNetwork();
  if (isOnline.value && eventId.value !== null) {
    requestPushPermission(eventId.value);
  }
};

export const useEventState = () => ({
  eventId,
  initializeEventData,
  eventData,
  getHomepage,
  mainItems,
  overflowItems,
});
