import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from '@/routes';
import 'bootstrap';
import 'material-icons/iconfont/material-icons.css';
import '@/style/style.scss';
import axios from 'axios';
import en from '@/translations/en';
import nl from '@/translations/nl';
import { createI18n } from 'vue-i18n';

export const i18n = createI18n({
  locale: 'nl', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: { en, nl },
  legacy: false,
});

createApp(App).use(router).use(i18n).mount('#app');

axios.defaults.baseURL = process.env.VUE_APP_API_HOST;
axios.defaults.headers.common['Channel'] = 'web-';

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
});
