export default {
  start: {
    btnSignInRequest: 'Verzoek login link',
    btnSignInQR: 'Login via QR',
    btnSignInQRHelp: 'Of klik op de ontvangen link uit je mail',
  },
  loginRequest: {
    modalTitle: 'Bekijk je inbox',
    modalBody:
      'Als we een overeenkomende registratie hebben gevonden, ontvangt u een e-mail met een QR-code.<br/>U kunt zich aanmelden bij de app door deze te scannen of door op de inloglink vanaf uw telefoon te klikken.',
    btn: {
      toStart: 'Back to start',
      scanQr: 'Scan QR code',
    },
  },
  navigation: {
    more: 'Meer',
    pages: "Pagina's",
    contacts: 'Contacten',
    myContacts: 'Mijn contacten',
    scanBadge: 'Scan nieuwe contacten',
    actions: 'Acties',
    logout: 'Log uit',
  },
  network: {
    offline: 'Offline modus<br/>Sommige content is misschien niet up-to-date',
    online: 'Online',
    newContentAvailable: 'Nieuwe content is beschikbaar',
    reload: 'herlaad',
  },
  profile: {
    ticketList: 'Mijn Ticket(s)',
    connected: 'Gebruiker toegevoegd aan contacten',
  },
  button: {
    close: 'Sluit',
  },
  filter: {
    contacts: 'Contacten',
    showResults: 'Toon resultaten',
  },
  postCategory: {
    view: 'Bekijk',
  },
  attendeeList: {
    addContact: 'Contacten toevoegen',
    addContactInfo:
      'Laat iemand je QR-code scannen binnen de app om te verbinden en om contactinformatie te delen.',
  },
  program: {
    agendaFull: 'Volledige agenda',
    agendaRegistered: 'Mijn agenda',
    empty: 'Geen items gevonden voor deze dag',
  },
  form: {
    eventCode: 'Event code',
    email: 'E-mail',
    btn: {
      request: 'Verzoek',
    },
  },
};
