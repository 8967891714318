// Import the functions you need from the SDKs you need
import { FirebaseOptions } from 'firebase/app';

export const firebaseOptions: FirebaseOptions = {
  apiKey: 'AIzaSyCVtrWwBAUVBY8H5SdTCOvccgn0src8w-Y',
  authDomain: 'eventleader-feab4.firebaseapp.com',
  projectId: 'eventleader-feab4',
  storageBucket: 'eventleader-feab4.appspot.com',
  messagingSenderId: '1034937435977',
  appId: '1:1034937435977:web:8b4db8219f1cd9aebcbc98',
};

export const vapidKey =
  'BA35O9vOlPzX9Nidxn2wrYmhfXpWP2amrG-Hi4-QVxslvg_NENr45g_JmLJxxL1wHvRKuibOW4nqlg4WE-t-WaI';
