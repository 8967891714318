<template>
  <div
    class="update-notification-bar w-100 text-bg-dark d-flex justify-content-between position-fixed"
    v-if="showReloadBar"
  >
    <div class="p-3">{{ $t('network.newContentAvailable') }}</div>
    <a
      class="p-3 text-decoration-none refresh-button text-uppercase"
      @click="refreshApp"
    >
      {{ $t('network.reload') }}
    </a>
  </div>
  <RouterView />
</template>

<script setup>
import { ref } from 'vue';

const refreshing = ref(false);
const showReloadBar = ref(false);

const loadDate = new Date();

// Prevent multiple refreshes
navigator.serviceWorker.addEventListener('controllerchange', () => {
  if (new Date().getTime() - loadDate.getTime() < 4000) {
    refreshApp();
  } else {
    showReloadBar.value = true;
  }
});

const refreshApp = () => {
  if (refreshing.value) {
    return;
  }
  refreshing.value = true;
  window.location.reload();
};
</script>

<style lang="scss">
.update-notification-bar {
  .refresh-button {
    color: #a1bff5;
  }
  z-index: 100000;
  bottom: env(safe-area-inset-bottom, 0px);
}
</style>
